/* -----------------
--- Video
--------------------*/
.bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video {
	object-fit: cover;
	z-index: -100;
}

.overlay {
	z-index: -99;
	background: rgba(0, 0, 0, 0.8);
}
