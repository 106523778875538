.center {
	margin: 0 auto;
	flex-direction: column;
	max-width: 300px;
	width: 300px;
	color: initial;
	flex-grow: 1;
}

.zipInput {
	position: relative;
	font-size: 14px;
	letter-spacing: 0.01rem;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	padding-left: 12px;
	margin-left: 0;
	background: #ffffff;
	border: 1px solid #cacaca;
	border-radius: 5px;
	line-height: 25px;
	height: 35px;
	width: 300px;
	outline: none;
}
