.title a,
.subtitle a {
	color: #0070f3;
	text-decoration: none;
}

.minitext a,
.text a {
	color: #fff;
	text-decoration: none;
}

.minitext a:hover,
.minitext a:focus,
.minitext a:active,
.title a:hover,
.title a:focus,
.title a:active,
.subtitle a:hover,
.subtitle a:focus,
.subtitle a:active {
	text-decoration: underline;
}

.title.xltitle {
	font-size: 400%;
}

.title {
	margin: 0;
	line-height: 1.15;
	font-size: 300%;
	font-family: modak;
	letter-spacing: 1px;
}

.subtitle {
	margin: 0;
	line-height: 1;
	font-size: 150%;

	font-family: 'Helvetica Neue';
	/* font-family: 'M PLUS Rounded 1c'; */
	/* font-weight: 500; */
}

.text {
	font-family: 'Helvetica Neue';
	/* font-family: 'M PLUS Rounded 1c'; */
	/* font-weight: 700; */
}

.subtext {
	font-size: 90%;
	color: #888888;
	font-family: 'Helvetica Neue';
	/* font-family: 'M PLUS Rounded 1c'; */
	/* font-weight: 700; */
}

.minitext {
	font-size: 60%;
	color: #fff;
	font-family: 'Helvetica Neue';
	/* font-family: 'M PLUS Rounded 1c'; */
	/* font-weight: 700; */
}

.title,
.subtitle,
.description {
	text-align: center;
}

.clickableText {
	text-decoration: underline;
	display: inline-block;
	cursor: pointer;
}
