.mainWrapper {
	height: 40px;
	margin: 1rem auto;
	display: flex;
	width: 100%;
	align-items: center;
}

.pointer {
	cursor: pointer;
}

.title {
	font-size: 65%;
}

.spacer {
	flex: 1;
}

.iconSpacer {
	width: 2em;
}
