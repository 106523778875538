.containerWrapper {
	display: flex;
	flex-direction: column;
}

.spacer {
	flex: 1;
}

.logo {
	height: 1em;
	margin-left: 0.5rem;
}

/* -----------------
--- Description 
--------------------*/
.description {
	padding: 2rem 0;
	line-height: 1.5;
}

.description:first-letter {
	text-transform: uppercase;
}

/* -----------------
--- Footer
--------------------*/

.spotifyWrapper {
	margin: 0 auto;
	text-align: center;
	padding-bottom: 2rem;
}

.spotifyWrapper button {
	width: 100%;
	padding: 0.5rem;
	padding-right: 1rem;
	font-size: 140%;
}

.spotifyWrapper svg {
	height: 2rem;
}

.spotifyWrapper p {
	margin: 0.3em 1em;
}
